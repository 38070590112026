var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"breadCurmb mt-3"},[_c('label',[_c('router-link',{attrs:{"to":"/"}},[_vm._v(_vm._s(_vm.$t("Home")))])],1),_c('span',[_vm._v("/")]),_c('label',{attrs:{"active":""}},[_vm._v(_vm._s(_vm.$t("SystemConstant")))]),_c('span',[_vm._v("/")]),_c('label',[_c('router-link',{attrs:{"to":{name: 'package'}}},[_vm._v(_vm._s(_vm.$t("Packages")))])],1),_c('span',[_vm._v("/")]),_c('label',{attrs:{"active":""}},[_vm._v(_vm._s(_vm.$route.params.id? _vm.$t("Edit"): _vm.$t('Add')))])]),_c('v-toolbar-title',{staticClass:"mt-3"},[_vm._v(_vm._s(_vm.$route.params.id? _vm.$t('EditPackage') : _vm.$t('AddNewPackage')))]),_c('v-tabs',{staticClass:"mt-2",attrs:{"background-color":"primary","dark":""},model:{value:(_vm.activeName),callback:function ($$v) {_vm.activeName=$$v},expression:"activeName"}},[_c('v-tab',[_vm._v(_vm._s(_vm.$t('PrimaryData')))]),_c('v-tab',[_vm._v(_vm._s(_vm.$t('PackageDetails')))]),_c('v-tab',[_vm._v(_vm._s(_vm.$t('PackageFeatures')))])],1),_c('v-tabs-items',{model:{value:(_vm.activeName),callback:function ($$v) {_vm.activeName=$$v},expression:"activeName"}},[_c('v-tab-item',[_c('v-card-text',[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":6}},[_c('div',{class:{
               'has-error': _vm.errors.has(`addEditValidation.name_ar`),
             }},[_c('label',[_vm._v(_vm._s(_vm.$t('NameAr')))]),_c('v-text-field',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],staticClass:"d-block my-2",attrs:{"type":"text","name":"name_ar","data-vv-scope":"addEditValidation","data-vv-as":_vm.$t('NameAr'),"hide-details":"","dense":"","outlined":""},model:{value:(_vm.formData.name.ar),callback:function ($$v) {_vm.$set(_vm.formData.name, "ar", $$v)},expression:"formData.name.ar"}})],1),(_vm.errors.has(`addEditValidation.name_ar`))?_c('div',{staticClass:"help-block"},[_vm._v(" "+_vm._s(_vm.errors.first(`addEditValidation.name_ar`))+" ")]):_vm._e()]),_c('v-col',{staticClass:"py-0",attrs:{"cols":6}},[_c('div',{class:{
               'has-error': _vm.errors.has(`addEditValidation.name_en`),
             }},[_c('label',[_vm._v(_vm._s(_vm.$t('NameEn')))]),_c('v-text-field',{directives:[{name:"validate",rawName:"v-validate",value:(''),expression:"''"}],staticClass:"d-block my-2",attrs:{"type":"text","name":"name_en","data-vv-scope":"addEditValidation","data-vv-as":_vm.$t('NameEn'),"hide-details":"","dense":"","outlined":""},model:{value:(_vm.formData.name.en),callback:function ($$v) {_vm.$set(_vm.formData.name, "en", $$v)},expression:"formData.name.en"}})],1),(_vm.errors.has(`addEditValidation.name_en`))?_c('div',{staticClass:"help-block"},[_vm._v(" "+_vm._s(_vm.errors.first(`addEditValidation.name_en`))+" ")]):_vm._e()]),_c('v-col',{staticClass:"py-0",attrs:{"cols":6}},[_c('div',{class:{
               'has-error': _vm.errors.has(`addEditValidation.price`),
             }},[_c('label',[_vm._v(_vm._s(_vm.$t('Price')))]),_c('v-text-field',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],staticClass:"d-block my-2",attrs:{"type":"number","name":"price","data-vv-scope":"addEditValidation","data-vv-as":_vm.$t('Price'),"hide-details":"","dense":"","outlined":""},model:{value:(_vm.formData.price),callback:function ($$v) {_vm.$set(_vm.formData, "price", $$v)},expression:"formData.price"}})],1),(_vm.errors.has(`addEditValidation.price`))?_c('div',{staticClass:"help-block"},[_vm._v(" "+_vm._s(_vm.errors.first(`addEditValidation.price`))+" ")]):_vm._e()]),_c('v-col',{staticClass:"py-0",attrs:{"cols":6}},[_c('div',{class:{
               'has-error': _vm.errors.has(`addEditValidation.cost`),
             }},[_c('label',[_vm._v(_vm._s(_vm.$t('Cost')))]),_c('v-text-field',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],staticClass:"d-block my-2",attrs:{"type":"number","name":"cost","data-vv-scope":"addEditValidation","data-vv-as":_vm.$t('Cost'),"hide-details":"","dense":"","outlined":""},model:{value:(_vm.formData.cost),callback:function ($$v) {_vm.$set(_vm.formData, "cost", $$v)},expression:"formData.cost"}})],1),(_vm.errors.has(`addEditValidation.cost`))?_c('div',{staticClass:"help-block"},[_vm._v(" "+_vm._s(_vm.errors.first(`addEditValidation.cost`))+" ")]):_vm._e()])],1)],1)],1)],1),_c('v-tab-item',[_c('v-card-text',[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":6}},[_c('div',{class:{
               'has-error': _vm.errors.has(`addEditValidation.number_of_meals`),
             }},[_c('label',[_vm._v(_vm._s(_vm.$t('NumberOfMeals')))]),_c('v-text-field',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],staticClass:"d-block my-2",attrs:{"type":"text","name":"number_of_meals","data-vv-scope":"addEditValidation","data-vv-as":_vm.$t('NumberOfMeals'),"hide-details":"","dense":"","outlined":""},model:{value:(_vm.formData.number_of_meals),callback:function ($$v) {_vm.$set(_vm.formData, "number_of_meals", $$v)},expression:"formData.number_of_meals"}})],1),(_vm.errors.has(`addEditValidation.number_of_meals`))?_c('div',{staticClass:"help-block"},[_vm._v(" "+_vm._s(_vm.errors.first(`addEditValidation.number_of_meals`))+" ")]):_vm._e()]),_c('v-col',{staticClass:"py-0",attrs:{"cols":6}},[_c('div',{class:{
               'has-error': _vm.errors.has(`addEditValidation.number_of_days`),
             }},[_c('label',[_vm._v(_vm._s(_vm.$t('NumberOfDays')))]),_c('v-text-field',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],staticClass:"d-block my-2",attrs:{"type":"text","name":"number_of_days","data-vv-scope":"addEditValidation","data-vv-as":_vm.$t('NumberOfDays'),"hide-details":"","dense":"","outlined":""},model:{value:(_vm.formData.number_of_days),callback:function ($$v) {_vm.$set(_vm.formData, "number_of_days", $$v)},expression:"formData.number_of_days"}})],1),(_vm.errors.has(`addEditValidation.number_of_days`))?_c('div',{staticClass:"help-block"},[_vm._v(" "+_vm._s(_vm.errors.first(`addEditValidation.number_of_days`))+" ")]):_vm._e()]),_c('v-col',{staticClass:"py-0",attrs:{"cols":6}},[_c('div',{class:{
               'has-error': _vm.errors.has(`addEditValidation.days`),
             }},[_c('label',[_vm._v(_vm._s(_vm.$t('Days')))]),_c('v-autocomplete',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],staticClass:"d-block my-2",attrs:{"name":"number_of_days","data-vv-scope":"addEditValidation","data-vv-as":_vm.$t('Days'),"hide-details":"","dense":"","outlined":"","item-value":"id","item-text":"name","items":_vm.dayList,"clearable":"","multiple":""},model:{value:(_vm.formData.days),callback:function ($$v) {_vm.$set(_vm.formData, "days", $$v)},expression:"formData.days"}})],1),(_vm.errors.has(`addEditValidation.days`))?_c('div',{staticClass:"help-block"},[_vm._v(" "+_vm._s(_vm.errors.first(`addEditValidation.days`))+" ")]):_vm._e()])],1)],1)],1)],1),_c('v-tab-item',[_c('v-container',{attrs:{"fluid":""}},[_c('div',[_c('el-button',{attrs:{"type":"primary float-right mb-5"},on:{"click":_vm.addNew}},[_vm._v(_vm._s(_vm.$t('Add')))])],1),_c('table',{attrs:{"id":"pacakgeFeaturesTable"}},[_c('tr',[_c('th',[_vm._v("#")]),_c('th',[_vm._v("اسم المجموعة")]),_c('th',[_vm._v("الكمية")]),_c('th',[_vm._v("العمليات")])]),_vm._l((_vm.formData.details),function(l,lIndex){return _c('tr',{key:lIndex},[_c('td',[_vm._v(" "+_vm._s(lIndex + 1)+" ")]),_c('td',[_c('div',{class:{
               'has-error': _vm.errors.has(`addEditValidation.group${lIndex}`),
             }},[_c('label',[_vm._v(_vm._s(_vm.$t('Group')))]),_c('v-autocomplete',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],staticClass:"d-block my-2",attrs:{"name":`group${lIndex}`,"data-vv-scope":"addEditValidation","data-vv-as":_vm.$t('Group'),"hide-details":"","dense":"","outlined":"","item-value":"id","item-text":"name","items":_vm.listGroup,"clearable":""},model:{value:(l.id),callback:function ($$v) {_vm.$set(l, "id", $$v)},expression:"l.id"}})],1),(_vm.errors.has(`addEditValidation.group${lIndex}`))?_c('div',{staticClass:"help-block"},[_vm._v(" "+_vm._s(_vm.errors.first(`addEditValidation.group${lIndex}`))+" ")]):_vm._e()]),_c('td',[_c('div',{class:{
               'has-error': _vm.errors.has(`addEditValidation.quantity${lIndex}`),
             }},[_c('label',[_vm._v(_vm._s(_vm.$t('Quantity')))]),_c('v-text-field',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],staticClass:"d-block my-2",attrs:{"name":`quantity${lIndex}`,"data-vv-scope":"addEditValidation","data-vv-as":_vm.$t('Quantity'),"hide-details":"","dense":"","outlined":""},model:{value:(l.quantity),callback:function ($$v) {_vm.$set(l, "quantity", $$v)},expression:"l.quantity"}})],1),(_vm.errors.has(`addEditValidation.quantity${lIndex}`))?_c('div',{staticClass:"help-block"},[_vm._v(" "+_vm._s(_vm.errors.first(`addEditValidation.quantity${lIndex}`))+" ")]):_vm._e()]),_c('td',[_c('v-icon',{staticClass:"my-0 mr-2 ml-2",attrs:{"dense":"","color":"danger"},on:{"click":function($event){return _vm.removeRow(lIndex);}}},[_vm._v("mdi-delete")])],1)])})],2)])],1)],1),_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{staticClass:"float-left"},[_c('v-btn',{staticClass:"mr-2 ml-5",attrs:{"color":"success","dark":"","outlined":"","small":""},on:{"click":_vm.save}},[_vm._v(" "+_vm._s(_vm.$t("Save"))+" ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }